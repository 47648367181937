/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useLocation, useSearch } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import { useApi } from '../../contexts/ApiContext';
import { useRequestBase } from '../../contexts/RequestContext';
import FacebookIcon from '../icons/socials/FacebookIcon';
import GoogleIcon from '../icons/socials/GoogleIcon';
import RoundedButton from './RoundedButton';

import styles from '../../styles/buttons/sso-button.module.css';

const providers = [
    {
        id: 'facebook',
        label: 'Facebook',
        icon: FacebookIcon,
    },
    {
        id: 'google',
        label: 'Google',
        icon: GoogleIcon,
    },
];

const propTypes = {
    provider: PropTypes.string.isRequired,
    nextUrl: PropTypes.string,
    subscribe: PropTypes.string,
    source: PropTypes.string,
    className: PropTypes.string,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    nextUrl: null,
    source: null,
    subscribe: null,
    className: null,
    buttonRef: null,
};

function SsoButton({ provider, nextUrl, source, subscribe, className, buttonRef, ...props }) {
    const api = useApi();
    const baseUri = useRequestBase();
    const [location] = useLocation();
    const locationSearch = useSearch();
    const absoluteNextUrl = `${baseUri}${location}?${locationSearch}`;
    const { baseUrl: authBaseUrl = null } = api.auth;
    const { label, icon: Icon } = providers.find(({ id }) => id === provider) || {};
    const finalNextUrl = nextUrl || absoluteNextUrl;
    const query = useMemo(
        () =>
            queryString.stringify({
                next: finalNextUrl,
                source,
                subscribe,
            }),
        [finalNextUrl, source, subscribe],
    );
    return (
        <RoundedButton
            href={`${authBaseUrl}/${provider}?${query}`}
            external
            target="_self"
            ref={buttonRef}
            className={classNames([styles.container, className])}
            {...props}
        >
            <Icon className={styles.icon} />
            <span className={styles.label}>{label}</span>
        </RoundedButton>
    );
}

SsoButton.propTypes = propTypes;
SsoButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <SsoButton {...props} buttonRef={ref} />);
