import { useRoutes } from '@folklore/routes';
import {
    useQuery,
    useQueryClient,
    keepPreviousData as previousPlaceholder,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { useRoute } from 'wouter';

import { useApi } from '../contexts/ApiContext';

export function fetchDocument(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['document', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.findBySlug(queryParam, { signal }),
    });
}

export function prefetchDocument(queryClient, api, slug) {
    return queryClient.prefetchQuery({
        queryKey: ['document', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.findBySlug(queryParam, { signal }),
    });
}

export function usePrefetchDocument() {
    const api = useApi();
    const queryClient = useQueryClient();
    return useCallback((slug) => prefetchDocument(queryClient, api, slug), [queryClient, api]);
}

export default function useDocument(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const {
        data: document = null,
        isFetched = false,
        ...queryResult
    } = useQuery({
        queryKey: ['document', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.documents.findBySlug(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : undefined,
        enabled: slug !== null,
        ...opts,
    });

    return {
        document,
        notFound: document === null && isFetched,
        isFetched,
        ...queryResult,
    };
}

export function useCurrentDocument() {
    const routes = useRoutes();
    const [, documentParams] = useRoute(routes.document);
    const { slug: documentSlug = null } = documentParams || {};
    const { document = null } = useDocument(documentSlug);
    return document;
}

export function fetchDocumentPreview(queryClient, api, token) {
    return queryClient.fetchQuery({
        queryKey: ['document_preview', token],
        queryFn: ({ queryKey: [, tokenParam], signal }) =>
            api.documents.findPreviewToken(tokenParam, { signal }),
    });
}

export function useDocumentPreview(token, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const {
        data: document = null,
        isFetched = false,
        ...queryResult
    } = useQuery({
        queryKey: ['document_preview', token],
        queryFn: ({ queryKey: [, tokenParam], signal }) =>
            api.documents.findPreviewToken(tokenParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : undefined,
        enabled: token !== null,
        ...opts,
    });

    return {
        document,
        notFound: document === null && isFetched,
        isFetched,
        ...queryResult,
    };
}
