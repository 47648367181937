/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import { useSpring, easings, animated } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useDefaultBrand } from '../../contexts/SiteContext';

import styles from '../../styles/icons/brand-logo-header.module.css';

import dehors from '../../assets/img/brands/dehors_header.svg';
import dehorsSponsor from '../../assets/img/brands/dehors_sponsor.svg';
// import france from '../../assets/img/brands/france_header.png';
import mollo from '../../assets/img/brands/mollo_header.svg';
import quatre95 from '../../assets/img/brands/quatre95_header.svg';
import quatre95Sponsor from '../../assets/img/brands/quatre95_sponsor.svg';
import urbania from '../../assets/img/brands/urbania_header.png';
import periodelibre from '../../assets/img/brands/periodelibre_header.svg';

const brands = [
    {
        id: 'urbania',
        logo: urbania,
        opacity: 1,
    },
    {
        id: 'france',
        logo: urbania,
        opacity: 1,
    },
    {
        id: 'dehors',
        logo: dehors,
        // sponsor: dehorsSponsor,
        opacity: 0.3,
    },
    {
        id: 'quatre95',
        logo: quatre95,
        sponsor: quatre95Sponsor,
        opacity: 0.5,
    },
    {
        id: 'mollo',
        logo: mollo,
        opacity: 0.5,
    },
    {
        id: 'periodelibre',
        logo: periodelibre,
        opacity: 0.3,
    },
];

const propTypes = {
    brand: PropTypes.string,
    withSponsor: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    withSponsor: false,
    className: null,
};

function BrandLogo({ brand, withSponsor, className }) {
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const finalBrandHandle = brand || defaultBrandHandle;

    const finalBrand = brands.find(({ id }) => id === finalBrandHandle) || null;

    const { id = 'urbania', logo = null, sponsor = null, opacity = 1 } = finalBrand || {};

    const hasSponsor = sponsor !== null;

    const logoStyle = useSpring({
        config: {
            duration: 500,
            easing: easings.easeOutCubic,
        },
        to:
            withSponsor && hasSponsor
                ? {
                      opacity,
                      scale: 0.9,
                  }
                : {
                      opacity: 1,
                      scale: 1,
                  },
    });

    const sponsorStyle = useSpring({
        config: {
            duration: 500,
            easing: easings.easeOutCubic,
        },
        to:
            withSponsor && hasSponsor
                ? {
                      opacity: 1,
                      scale: 1,
                  }
                : {
                      opacity: 0,
                      scale: 0.8,
                  },
    });

    return (
        <span className={classNames([styles.container, styles[id], className])}>
            <animated.img src={logo} className={styles.logo} alt="Logo" style={logoStyle} />
            {sponsor ? (
                <animated.img
                    src={sponsor}
                    className={styles.sponsor}
                    alt="Sponsor"
                    style={{ transform: 'translate(-50%, -50%)', ...sponsorStyle }}
                />
            ) : null}
        </span>
    );
}

BrandLogo.propTypes = propTypes;
BrandLogo.defaultProps = defaultProps;

export default BrandLogo;
