import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Image from '../partials/Image';
import PromotionDescription from '../typography/PromotionDescription';
import PromotionTitle from '../typography/PromotionTitle';

import styles from '../../styles/cards/promotion-card.module.css';

const propTypes = {
    image: AppPropTypes.image,
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf([null, 'image-popout']),
    callToAction: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    callToActionClassName: PropTypes.string,
};

const defaultProps = {
    image: null,
    title: null,
    description: null,
    theme: null,
    callToAction: null,
    children: null,
    className: null,
    innerClassName: null,
    imageClassName: null,
    pictureClassName: null,
    contentClassName: null,
    titleClassName: null,
    descriptionClassName: null,
    callToActionClassName: null,
};

function PromotionCard({
    image,
    title,
    description,
    theme,
    callToAction,
    children,
    className,
    innerClassName,
    imageClassName,
    pictureClassName,
    contentClassName,
    titleClassName,
    descriptionClassName,
    callToActionClassName,
}) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hasImage]: image !== null,
                },
                className,
            ])}
            data-theme={theme}
        >
            <div className={classNames([styles.inner, innerClassName])}>
                {image !== null ? (
                    <Image
                        media={image}
                        className={classNames([styles.image, imageClassName])}
                        pictureClassName={classNames([styles.picture, pictureClassName])}
                    />
                ) : null}
                <div className={classNames([styles.content, contentClassName])}>
                    {title !== null ? (
                        <PromotionTitle
                            className={classNames([styles.title, titleClassName])}
                            html={title}
                        />
                    ) : null}
                    {description !== null ? (
                        <PromotionDescription
                            className={classNames([styles.description, descriptionClassName])}
                            html={description}
                        />
                    ) : null}
                </div>
                {callToAction !== null ? (
                    <div className={classNames([styles.callToAction, callToActionClassName])}>
                        {callToAction}
                    </div>
                ) : null}
                {children}
            </div>
        </div>
    );
}

PromotionCard.propTypes = propTypes;
PromotionCard.defaultProps = defaultProps;

export default PromotionCard;
