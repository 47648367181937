/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCurrentDocument } from '../../hooks/useDocument';

import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import SsoButton from '../buttons/SsoButton';
import SubscriptionForm from '../forms/SubscriptionForm';
import DocumentTypeIcon from '../icons/DocumentTypeIcon';
import LineSpacer from '../partials/LineSpacer';
import PromotionCard from './PromotionCard';

import styles from '../../styles/cards/promotion-subscription-card.module.css';

// @TODO: Swtch input type
const propTypes = {
    handle: PropTypes.string,
    source: PropTypes.string,
    subscription: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    titleLogin: PropTypes.string,
    descriptionLogin: PropTypes.string,
    theme: PropTypes.oneOf([null, 'image-popout', 'full']),
    onComplete: PropTypes.func,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    pictureClassName: PropTypes.string,
    callToActionClassName: PropTypes.string,
};

const defaultProps = {
    handle: null,
    source: null,
    subscription: 'urbania',
    title: 'Abonnez-vous à<br/>l’infolettre URBANIA',
    description: 'Chaque lundi et mercredi : nos contenus qui soulèvent les passions.',
    titleLogin: null,
    descriptionLogin: null,
    theme: 'urbania',
    onComplete: null,
    className: null,
    innerClassName: null,
    titleClassName: null,
    descriptionClassName: null,
    imageClassName: null,
    pictureClassName: null,
    callToActionClassName: null,
};

function PromotionSubscriptionCard({
    source,
    handle,
    title,
    description,
    titleLogin,
    descriptionLogin,
    subscription,
    theme,
    className,
    innerClassName,
    titleClassName,
    descriptionClassName,
    imageClassName,
    pictureClassName,
    callToActionClassName,
    onComplete,
    ...props
}) {
    const user = useUser();
    const currentDocument = useCurrentDocument();
    const { type = null, image, images: { cover = null } = {} } = currentDocument || {};
    const finalImage = cover || image;
    const { subscriptions: allSubscriptions } = useSite();
    const { addMessage } = usePopupsContext();
    const tracking = useTracking();
    const [login, setLogin] = useState(false);
    const onClickLogin = useCallback(() => {
        setLogin(!login);
    }, [login]);
    const onWizardComplete = useCallback(
        (newUser) => {
            if (login) {
                addMessage('hi', {
                    user: newUser,
                    isNew: false,
                });
            } else {
                addMessage('subscribe', {
                    subscription: allSubscriptions.find(
                        ({ handle: subscriptionHandle }) => subscriptionHandle === subscription,
                    ),
                });
            }

            if (onComplete !== null) {
                onComplete(newUser);
            }
        },
        [onComplete, addMessage, subscription],
    );

    const onClickSso = useCallback(
        (provider) => {
            tracking.trackEvent('Auth', 'click_sso', provider);
        },
        [tracking],
    );

    return (
        <PromotionCard
            callToAction={
                <>
                    <SubscriptionForm
                        // withPopout
                        // withFull
                        subscribeButtonLabel={
                            login ? (
                                <FormattedMessage
                                    defaultMessage="Connexion"
                                    description="Button label"
                                />
                            ) : null
                        }
                        subscription={subscription}
                        source={source || handle || `promotion-${subscription}`}
                        onComplete={onWizardComplete}
                    />

                    {theme === 'full' ? (
                        <>
                            <LineSpacer className={styles.spacer}>
                                {login ? (
                                    <FormattedMessage
                                        defaultMessage="ou se connecter avec"
                                        description="Spacer label"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="ou s’abonner avec"
                                        description="Spacer label"
                                    />
                                )}
                            </LineSpacer>
                            <div className={styles.sso}>
                                {['facebook', 'google'].map((provider) => (
                                    <SsoButton
                                        key={`sso-${provider}`}
                                        provider={provider}
                                        source={source || handle || `promotion-${subscription}`}
                                        subscribe={subscription}
                                        className={styles.button}
                                        onClick={() => onClickSso(provider)}
                                    />
                                ))}
                            </div>
                            {user === null ? (
                                <div className={styles.login}>
                                    {!login ? (
                                        <FormattedMessage
                                            defaultMessage="Vous êtes déjà abonné·e? {link}"
                                            values={{
                                                link: (
                                                    <BasicButton
                                                        className={styles.link}
                                                        onClick={onClickLogin}
                                                    >
                                                        <FormattedMessage
                                                            defaultMessage="Connectez-vous"
                                                            description="Button label"
                                                        />
                                                    </BasicButton>
                                                ),
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="Vous n’êtes pas abonné·e? {link}"
                                            values={{
                                                link: (
                                                    <BasicButton
                                                        className={styles.link}
                                                        onClick={onClickLogin}
                                                    >
                                                        <FormattedMessage
                                                            defaultMessage="Abonnez-vous"
                                                            description="Button label"
                                                        />
                                                    </BasicButton>
                                                ),
                                            }}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </>
            }
            className={classNames([
                styles.container,
                {
                    [styles.isMicromag]: type === 'micromag',
                },
                className,
            ])}
            innerClassName={classNames([styles.inner, innerClassName])}
            titleClassName={classNames([styles.title, titleClassName])}
            descriptionClassName={classNames([styles.description, descriptionClassName])}
            imageClassName={classNames([styles.image, imageClassName])}
            pictureClassName={classNames([styles.picture, pictureClassName])}
            callToActionClassName={classNames([styles.callToAction, callToActionClassName])}
            theme={theme}
            title={login ? titleLogin || title : title}
            description={login ? descriptionLogin || description : description}
            image={finalImage}
            {...props}
        >
            {theme === 'full' && type !== null ? (
                <DocumentTypeIcon type={type} className={styles.typeIcon} />
            ) : null}
        </PromotionCard>
    );
}

PromotionSubscriptionCard.propTypes = propTypes;
PromotionSubscriptionCard.defaultProps = defaultProps;

export default PromotionSubscriptionCard;
