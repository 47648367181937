/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAuthLogout } from '../../hooks/useAuth';
import { useSubscribe } from '../../hooks/useSubscription';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSite } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import FormButton from '../buttons/FormButton';
import SubscriptionHorizontalCard from '../cards/SubscriptionHorizontalCard';
import EmailPhoneField from '../fields/EmailPhoneField';
import UserField from '../fields/UserField';
import LoadingIcon from '../icons/LoadingIcon';
import FormStatus from '../partials/FormStatus';
import FormControl from './FormControl';

import styles from '../../styles/forms/subscription-form.module.css';

const propTypes = {
    subscription: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    source: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.node,
    full: PropTypes.bool,
    subscribeButtonLabel: PropTypes.bool,
    withoutClose: PropTypes.bool,
    withoutCancel: PropTypes.bool,
    withoutButtons: PropTypes.bool,
    withoutSubscriptions: PropTypes.bool,
    withoutLogout: PropTypes.bool,
    fieldRef: AppPropTypes.ref,
    inputRef: AppPropTypes.ref,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onComplete: PropTypes.func,
    onCancel: PropTypes.func,
    onFieldPointerUp: PropTypes.func,
    className: PropTypes.string,
    fieldsClassName: PropTypes.string,
    buttonsClassName: PropTypes.string,
    subscriptionsClassName: PropTypes.string,
};

const defaultProps = {
    placeholder: null,
    source: null,
    label: null,
    full: false,
    subscribeButtonLabel: null,
    withoutClose: false,
    withoutCancel: false,
    withoutButtons: false,
    withoutSubscriptions: false,
    withoutLogout: false,
    fieldRef: null,
    inputRef: null,
    onFocus: null,
    onBlur: null,
    onComplete: null,
    onCancel: null,
    onFieldPointerUp: null,
    className: null,
    fieldsClassName: null,
    buttonsClassName: null,
    subscriptionsClassName: null,
};

function SubscriptionForm({
    subscription,
    source,
    placeholder,
    label,
    full,
    subscribeButtonLabel,
    withoutClose,
    withoutCancel,
    withoutButtons,
    withoutSubscriptions,
    withoutLogout,
    fieldRef,
    inputRef,
    onFocus,
    onBlur,
    onComplete,
    onCancel,
    onFieldPointerUp,
    className,
    fieldsClassName,
    buttonsClassName,
    subscriptionsClassName,
}) {
    const intl = useIntl();
    const url = useUrlGenerator();
    const { subscribeAsync } = useSubscribe();
    const formFields = useMemo(() => ['field', 'phone', 'email'].filter(Boolean), []);

    const [fieldType, setFieldType] = useState('email');
    const onFieldTypeChange = useCallback((newType) => setFieldType(newType), [setFieldType]);

    const [subscriptions, setSubscriptions] = useState(subscription !== null ? [subscription] : []);
    const user = useUser();
    const { subscriptions: userSubscriptions = [] } = user || {};
    const initialSubscribed =
        userSubscriptions.findIndex(
            ({ id, subscribed = false }) => id === subscription && subscribed,
        ) !== -1;
    const [completed, setCompleted] = useState(initialSubscribed);

    const postSendToken = useCallback(
        (formAction, { field }) =>
            subscribeAsync(
                full
                    ? {
                          subscriptions,
                          source,
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      }
                    : {
                          subscription,
                          source,
                          [fieldType === 'tel' ? 'phone' : 'email']: field,
                      },
            ),
        [fieldType, subscribeAsync, source, subscription, subscriptions],
    );
    const tracking = useTracking();
    const onFormComplete = useCallback(() => {
        setCompleted(true);
        if (onComplete !== null) {
            onComplete();
        }
        tracking.trackSubscribe(subscription, source);
    }, [onComplete, tracking, source, subscription]);

    const { fields, onSubmit, status, setValue } = useForm({
        fields: formFields,
        postForm: postSendToken,
        onComplete: onFormComplete,
    });

    const { errors } = fields[fieldType === 'tel' ? 'phone' : 'email'] || {};

    const { subscriptions: allSubscriptions } = useSite();
    const availableSubscriptions = useMemo(
        () =>
            allSubscriptions
                .filter(({ handle, type }) => type !== 'notification' || handle === subscription)
                .filter(
                    ({ handle }) =>
                        userSubscriptions.findIndex(
                            ({ id, subscribed }) => id === handle && subscribed,
                        ) === -1 ||
                        handle === subscription ||
                        completed,
                )
                .sort(({ handle: aHandle }, { handle: bHandle }) => {
                    if (aHandle === subscription) {
                        return -1;
                    }
                    if (bHandle === subscription) {
                        return 1;
                    }
                    return 0;
                }),
        [allSubscriptions, subscription],
    );

    const onSubscriptionChange = useCallback(
        ({ handle }, checked) => {
            setSubscriptions(
                checked ? [...subscriptions, handle] : subscriptions.filter((s) => s !== handle),
            );
        },
        [setSubscriptions, subscriptions],
    );

    const onClickCancel = useCallback(() => {
        setValue(null);
        if (onCancel !== null) {
            onCancel();
        }
    }, [setValue, onCancel]);

    const { logout } = useAuthLogout();
    const onClickLogout = useCallback(
        (e) => {
            e.preventDefault();
            logout();
            setCompleted(false);
        },
        [logout],
    );

    return (
        <form
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                    [styles.subscribed]: completed,
                    [styles.loading]: status === 'loading',
                    [styles.full]: full,
                    [className]: className !== null,
                },
            ])}
            onSubmit={onSubmit}
        >
            <div className={classNames([styles.fields, fieldsClassName])}>
                <FormControl
                    className={styles.formControl}
                    label={label}
                    {...fields[fieldType === 'tel' ? 'phone' : 'email']}
                >
                    <div className={styles.fieldGroup} ref={fieldRef}>
                        {user !== null ? (
                            <UserField className={styles.field} withBorder />
                        ) : (
                            <EmailPhoneField
                                {...fields.field}
                                ref={inputRef}
                                errors={errors}
                                className={styles.field}
                                placeholder={
                                    placeholder ||
                                    intl.formatMessage({
                                        defaultMessage: 'Entrez votre n de tel ou courriel',
                                        description: 'Field placeholder',
                                    })
                                }
                                onTypeChange={onFieldTypeChange}
                                onFocus={onFocus}
                                onPointerUp={onFieldPointerUp}
                                onBlur={onBlur}
                            />
                        )}
                        <LoadingIcon className={styles.loadingIcon} />
                        <FormButton type="submit" dark className={styles.primaryButton}>
                            {subscribeButtonLabel || (
                                <FormattedMessage
                                    defaultMessage="S'abonner"
                                    description="Button label"
                                />
                            )}
                        </FormButton>
                    </div>
                </FormControl>
                {user !== null && !withoutLogout ? (
                    <div className={styles.auth}>
                        <FormattedMessage
                            defaultMessage="Ce n’est pas vous? {link}"
                            values={{
                                link: (
                                    <BasicButton className={styles.link} onClick={onClickLogout}>
                                        <FormattedMessage
                                            defaultMessage="Déconnectez-vous"
                                            description="Button label"
                                        />
                                    </BasicButton>
                                ),
                            }}
                        />
                    </div>
                ) : null}
            </div>
            {!withoutSubscriptions ? (
                <div className={classNames([styles.subscriptions, subscriptionsClassName])}>
                    {availableSubscriptions.map((subscriptionItem) => (
                        <SubscriptionHorizontalCard
                            {...subscriptionItem}
                            thumbnailWidth={50}
                            source="account"
                            checked={subscriptions.indexOf(subscriptionItem.handle) !== -1}
                            withCheckbox={!completed}
                            withToggle={completed}
                            className={styles.subscription}
                            onChange={(checked) => onSubscriptionChange(subscriptionItem, checked)}
                        />
                    ))}
                </div>
            ) : null}
            {!withoutButtons ? (
                <div className={classNames([styles.buttons, buttonsClassName])}>
                    {!completed && !withoutCancel ? (
                        <FormButton
                            transparent
                            className={styles.secondaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Annuler" description="Button label" />
                        </FormButton>
                    ) : null}
                    <FormStatus
                        status={status}
                        successMessage="Merci!"
                        iconRight
                        className={styles.status}
                    />
                    {!completed ? (
                        <FormButton
                            type="submit"
                            dark
                            className={styles.primaryButton}
                            disabled={status === 'loading'}
                        >
                            {subscribeButtonLabel || (
                                <FormattedMessage
                                    defaultMessage="S'abonner"
                                    description="Button label"
                                />
                            )}
                        </FormButton>
                    ) : null}

                    {completed && !withoutClose ? (
                        <FormButton
                            type="button"
                            dark
                            className={styles.primaryButton}
                            onClick={onClickCancel}
                        >
                            <FormattedMessage defaultMessage="Fermer" description="Button label" />
                        </FormButton>
                    ) : null}
                </div>
            ) : null}
        </form>
    );
}

SubscriptionForm.propTypes = propTypes;
SubscriptionForm.defaultProps = defaultProps;

export default SubscriptionForm;
